.tablist-center {
  display: flex;
  justify-content: center;
}

.my-tabs .Mui-selected {
  color: green !important;
}

.my-tabs .MuiTabs-indicator {
  background-color: green !important;
}

.container-test {
  height: 100px;
  width: 420px;
}

.guidlinesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiButtonBase-root.MuiTab-root.MuiTab-textColorPrimary.tabStyle {
  color: black;
  letter-spacing: 1.5px;
}

.dashboardItem {
  margin-bottom: 15px;
  hr {
    margin-top: 10px;
  }
}

.detailsList {
  margin-left: 30px;
  margin-top: 10px;
}

.guidlinesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

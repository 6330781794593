.dashboardCardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 50px;
}

.filter {
  margin-bottom: 40px;
}

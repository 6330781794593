.faqContainer {
  margin-top: 40px;
  margin-bottom: 30px;
  margin: auto;
  display: flex;
  flex-direction: column;

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }

  .sectionTitle {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
  }
}

.mainButton {
  text-decoration: none;
}

.homeContainer {
  padding: 70px 0 0 0;
  width: 90%;
  margin: auto;
}

.whiteBackground {
  height: 100%;
  padding-bottom: 1px;
  background-color: white;
}

.cardFrame {
  flex-shrink: 0;
  cursor: pointer;
  width: 250px;
  height: 350px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.32);
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;

  &:last-child {
    margin-right: 5px;
  }

  img {
    width: 70px;
    height: 70px;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .title {
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .description {
    text-align: center;
  }
}

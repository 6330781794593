.heroContainer {
  display: flex;
  justify-content: space-between;

  p {
    font-size: 5vw;
    font-weight: 550;
    line-height: 1.4;
  }
  .heroText {
    width: 50%;
    margin-right: 30px;
  }

  .imageContainer {
    width: 50%;
    height: 50%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.cardContainer {
  width: 330px;
  height: 450px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;

  .textContainer {
    position: relative;
    padding: 15px;
    border: 1px 1px 1px 0px solid rgba(0, 0, 0, 0.32);
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    height: 100%;

    .title {
      margin-top: 15px;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: bold;
    }
    .description {
      height: 100px;
      font-size: 14px;
    }
  }

  .imageContainer {
    width: 100%;
    height: 50%;
  }

  .categoryContainer {
    display: flex;
    font-size: 12px;
    justify-content: space-between;
  }

  .buttonContainer {
    position: absolute;
    bottom: 20px;
    left: 30%;
  }

  img {
    border-radius: 20px 20px 0px 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

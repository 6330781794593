.featuresListOuterContainer {
  height: 410px; /* Example value, adjust as needed */
  overflow: hidden; /* Hide overflow from this container */

  .featuresListContainer {
    display: flex;
    flex-wrap: nowrap;
    gap: 70px;
    height: 400px;
    overflow-x: scroll;
    overflow-y: hidden;

    &::-webkit-scrollbar {
      width: 1px; /* width of the scrollbar */
      height: 7px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(195, 195, 195); /* color of the scroll thumb */
      border-radius: 20px; /* roundness of the scroll thumb */
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgb(
        127,
        126,
        126
      ); /* color of the scroll thumb on hover */
    }
  }
}

.listContainer {
  margin-top: 100px;

  span {
    font-size: 30px;
    font-weight: bold;
  }

  .explore {
    margin-right: 10px;
  }
  .features {
    color: #ead9c4;
  }

  .title {
    margin-bottom: 30px;
  }
}

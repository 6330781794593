@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap");
@import "./fonts";

:root {
  --body-bg: rgba(246, 244, 241, 1);
  --box-bg: #fff;
  --input-bg: #fff;
  --txt-color: #2f2d2f;
  --txt-second-color: #ccc;
  --border-color: #000000;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
$nespressoLucas: "NespressoLucas", sans-serif;
* {
  padding: 0;

  margin: 0;
  box-sizing: border-box;
  // font-family: $nespressoLucas !important;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
}

.App {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}

body {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--body-bg);
  color: var(--txt-color);
  height: 100vh;
}

.box {
  background-color: var(--box-bg);
  padding: 30px;
  border-radius: 20px;
  box-shadow: var(--box-shadow);
}

.header {
  margin-bottom: 30px;
  text-align: center;
}

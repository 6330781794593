.drop-file-preview {
  margin-top: 30px;

  &__title {
    margin-bottom: 20px;
  }

  p {
    font-weight: 500;
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    background-color: var(--input-bg);
    padding: 15px;
    border-radius: 8px;
    border: 1px solid var(--border-color);
    min-width: 420px;

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__del {
      background-color: #fff;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      //  position: absolute;
      //  right: 10px;
      //  top: 50%;
      //  transform: translateY(-50%);
      border: 0.8px solid black;
      // box-shadow: var(--box-shadow);
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.3s ease;
      color: black;
    }
  }
}

.drop-file-preview__item img {
  width: 30px;
  margin-right: 30px;
  cursor: pointer;
}

.file-name {
  max-width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drop-file-preview__item:hover .drop-file-preview__item__del {
  opacity: 1;
}

.preview-modal {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-modal-content {
  background-color: white;
  width: 80vw;
  max-width: 90%;
  height: 90vh;
  overflow-y: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
}

.preview-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.preview-modal-header h2 {
  margin: 0;
}

.preview-modal-body {
  display: flex;
  justify-content: center;
  align-items: center; /* center the content vertically */
  width: 100%;
  height: 100%;
}

.downloadIcon {
  width: 25px;
  height: 25px;
}

.preview-modal-body img {
  max-width: 100%;
  max-height: 100%;
}

.preview-modal-body iframe {
  width: 100%;
  height: 100%;
  border: none;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 90px;

  img {
    width: 30px;
    margin-right: 10px; // Adjust the spacing between the image and other elements
    cursor: pointer;
  }
  img:last-child {
    margin-right: 0;
  }
}

.checkbox {
  margin-right: 5px; // Adjust the spacing between the checkbox and the image
}

table {
  border-collapse: collapse;
  width: 100%;
  margin: 1em 0;
  font-size: 0.8em;
}

.ReactModal__Content {
  z-index: 2000;
  position: absolute !important;
  top: 45% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
  max-width: 900px;
  max-height: 80vh;
  overflow: auto;
}

thead th {
  position: sticky;
  top: -21px;
  background-color: #f2f2f2;
  z-index: 1;
}

th,
td {
  padding: 0.5em;
  border: 1px solid #ccc;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

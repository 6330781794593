form {
  width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorMessage {
  color: red;
  align-self: start;
  font-size: 12px;
}

.wrapper {
  background-size: cover; /* to cover the entire viewport */
  background-position: center; /* center the image */
  display: flex;
  justify-content: center;
  background-repeat: no-repeat;
  height: 100vh;
}
